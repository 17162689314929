import { useForm } from "react-hook-form";
import { Button } from "components/Button";
import TextField from "components/Client/TextField";
import Link from "next/link";

import { ErrorMessage } from "@components/ErrorMessage";
import GoogleAccountIcon from "@components/Icons/GoogleAccountIcon";
import PasswordTextfield from "@components/PasswordTextfield";

export type FormDataType = {
  email: string;
  password: string;
};

type EmailPasswordFormProps = {
  defaultValues: FormDataType;
  onSubmit: (data: FormDataType) => Promise<void>;
  onSubmitButtonText: string;
  onGoogleSubmit?: () => Promise<void>;
  resetPasswordHref?: string;
  errorMessage?: string;
  emailDisabled?: boolean;
  isNewAccount?: boolean;
};

const EmailPasswordForm: React.FC<EmailPasswordFormProps> = ({
  defaultValues = { email: "", password: "" },
  onSubmit,
  onSubmitButtonText = "Sign in",
  onGoogleSubmit,
  resetPasswordHref,
  errorMessage,
  isNewAccount = false,
  emailDisabled = false,
}) => {
  const formMethods = useForm<FormDataType>({
    defaultValues,
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = formMethods;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full max-w-sm space-y-6"
    >
      <div className="space-y-4">
        <TextField
          autoFocus
          label="Email"
          id="email"
          disabled={emailDisabled}
          errorMessage="Email is required"
          register={register}
          errors={errors}
          required
          isEmailErrorMessage="Email should be a valid email address"
          isEmail
          placeholder="Enter email"
        />
        <div className="space-y-1">
          <PasswordTextfield
            errors={errors}
            register={register}
            placeholder={isNewAccount ? "Choose a password" : undefined}
          />
          {resetPasswordHref && (
            <div className="flex justify-end text-sm">
              <Link
                href={resetPasswordHref}
                className="font-small text-foreground/50 hover:underline focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Forgot your password?
              </Link>
            </div>
          )}
        </div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </div>
      <div className="space-y-2">
        <Button
          className="w-full"
          type="submit"
          variant="primary-theme"
          isLoading={isSubmitting}
        >
          {onSubmitButtonText}
        </Button>
        {onGoogleSubmit && (
          <>
            <div className="text-grey-500 text-center">or</div>
            <Button
              className="w-full"
              type="button"
              icon={<GoogleAccountIcon className="w-6 h-6" />}
              variant="white"
              onClick={onGoogleSubmit}
            >
              Continue with Google
            </Button>
          </>
        )}
      </div>
    </form>
  );
};

export default EmailPasswordForm;
