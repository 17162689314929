import { useRouter } from "next/router";

import Popup from "@components/Popup/Popup";

interface ResetPopupProps {
  coachSlug?: string;
}

const ResetPopup: React.FC<ResetPopupProps> = ({ coachSlug }) => {
  const router = useRouter();
  const { resetPasswordAlert, confirmedPasswordReset } = router.query;
  const replaceUrl = coachSlug ? `/me/${coachSlug}/login` : "/login";
  const onClose = () => router.replace(replaceUrl);
  return (
    <>
      <Popup onClose={onClose} visible={resetPasswordAlert === "true"}>
        Reset requested, check your email.
      </Popup>
      <Popup onClose={onClose} visible={confirmedPasswordReset === "true"}>
        Reset confirmed! Please log in with your new password.
      </Popup>
    </>
  );
};

export default ResetPopup;
