import { FC, ReactNode, useCallback } from "react";
import { WorkspaceType } from "api-services/definitions/accounts";
import { SanitizedAccountType } from "api-services/definitions/integrations";
import { Redirect, ThemedSpinner } from "components/Redirect";

import { useAuth } from "@contexts/auth";
import { useDarkTheme } from "@contexts/theme";
import { displayNameFromContact } from "@lib/contacts";
import { SanitizedUserType } from "@lib/shared-types";
import { getSupportUrl } from "@lib/utils";

import { Button } from "@components/Button";
import ClientAvatar from "@components/Client/ClientAvatar";
import ClientLayout from "@components/Client/ClientLayout";
import ArrowIcon from "@components/Icons/ArrowIcon";

type LoginRedirectProps = {
  coach?: SanitizedUserType;
  contactEmail?: string;
  requestedPage?: string;
  children: ReactNode;
};

const displayOrgName = (
  org: SanitizedUserType,
  account: SanitizedAccountType
) => {
  if (org.companyName) return org.companyName;
  return displayNameFromContact(account);
};

const WorkspaceSelector: React.FC<{
  name: string;
  options: WorkspaceType[];
  onWorkspaceSelect: (workspace: WorkspaceType) => void;
}> = ({ name, options, onWorkspaceSelect }) => {
  const { aid, account, signout } = useAuth();
  useDarkTheme();
  const getRoleInformation = useCallback(
    (workspace: WorkspaceType) => {
      if (workspace.clientId) {
        return `You are ${displayOrgName(
          workspace.organization,
          workspace.organizationOwner
        )}'s client`;
      } else if (aid === workspace.organization.id) {
        return "Your workspace";
      } else {
        return "You are a member of this team";
      }
    },
    [aid]
  );

  const supportUrl = account ? getSupportUrl(account.email, "bug_report") : "";

  return (
    <ClientLayout>
      <div className="flex-1  w-full mx-auto px-8 max-w-screen-md flex flex-col">
        <div className="flex items-center w-full h-20">
          <img
            className="h-8 w-auto"
            src="/favicon-310.png"
            alt="Practice logo"
          />
          <div className="ml-4 text-2xl">Practice</div>
          <Button variant="ghost-theme" className="ml-auto" onClick={signout}>
            Sign out
          </Button>
        </div>
        <div className="py-10  items-center flex h-full flex-1 flex-col cursor-pointer">
          <div className="text-2xl mb-6">Hi, {name}, welcome back</div>
          <div className="flex-1 w-full max-w-md">
            {options.length > 0 ? (
              options.map((option) => (
                <div
                  key={option.organization.id}
                  className="rounded-lg my-2 p-3 flex bg-foreground/7 items-center justify-start border-foreground/10 border"
                  onClick={() => onWorkspaceSelect(option)}
                >
                  <ClientAvatar
                    className="ml-0 mr-4"
                    client={option.organization}
                  />
                  <div>
                    <div>
                      {displayOrgName(
                        option.organization,
                        option.organizationOwner
                      )}{" "}
                      organization
                    </div>
                    <div className="text-foreground/50 text-xs">
                      {getRoleInformation(option)}
                    </div>
                  </div>
                  <ArrowIcon className="ml-auto text-grey-500 -rotate-180 text-foreground/20" />
                </div>
              ))
            ) : (
              <div>
                <div
                  key="no-workspace"
                  className="rounded-lg my-2 p-4 flex bg-foreground/7 items-center justify-center border-foreground/10 border text-foreground/50 text-sm"
                >
                  No active workspaces
                </div>
                <div className="text-center text-sm mt-6">
                  For technical issues, please{" "}
                  <a href={supportUrl} target="_blank" className="underline">
                    contact support
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ClientLayout>
  );
};

const LoginRedirect: FC<LoginRedirectProps> = ({
  coach,
  requestedPage,
  children,
}) => {
  // this compoenent is responsible for redirecting the user once they're logged in
  // whether it's a client or a coach
  // this is old code that we decide to hide here
  // it could be improved especially if we decide to seperate the 2 logins flows
  // client login and coah login
  const { account, loading, setCurrentWorkspace, currentWorkspace, contexts } =
    useAuth();

  const onWorkspaceSelect = useCallback(
    (workspace: WorkspaceType) => {
      setCurrentWorkspace({
        id: workspace.organization.id,
        role: workspace.clientId ? "client" : "coach",
      });
    },
    [setCurrentWorkspace]
  );

  if (loading) return <ThemedSpinner coach={coach} />;

  if (account) {
    const redirect = (workspace: WorkspaceType) => {
      if (requestedPage) {
        return <Redirect to={requestedPage} coach={coach} />;
      }
      if (workspace.clientId) {
        return (
          <Redirect
            to={`/me/${workspace.organization.slug}/clients/${workspace.clientId}/messaging`}
            options={{ shallow: false }}
          />
        );
      } else {
        return <Redirect to="/home" options={{ shallow: false }} />;
      }
    };
    if (!contexts) return <ThemedSpinner coach={coach} />;
    if (currentWorkspace?.id) {
      const workspace = contexts.find((org) =>
        currentWorkspace.role === "coach"
          ? org.organization.id === currentWorkspace.id && !org.clientId
          : org.organization.id === currentWorkspace.id && org.clientId
      );
      if (workspace) return redirect(workspace);
    }
    if (contexts?.length === 1) {
      setCurrentWorkspace({
        id: contexts[0].organization.id,
        role: contexts[0].clientId ? "client" : "coach",
      });
      return redirect(contexts[0]);
    } else {
      return (
        <WorkspaceSelector
          options={contexts}
          onWorkspaceSelect={onWorkspaceSelect}
          name={displayNameFromContact(account)}
        />
      );
    }
  }

  return <>{children}</>;
};

export default LoginRedirect;
